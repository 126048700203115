.fiche--inner {
  .fiche-title {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .center-address {
    line-height: 23px;
    font-weight: normal;
    font-size: 14px;
    color: $gray;
  }
  .enseigne {
    font-size: 22px;
    color: #023866;
  }
  background-color: $color-brand-gray-light;
  padding-bottom: 20px;
  padding-top: 0;
  .card--content {
    .btn {
      margin-bottom: 1px;
    }
    min-height: 10px;
  }
  p.normal-color {
    color: inherit;
    font-weight: normal;
  }
  .icone--title {
    background-repeat: no-repeat;
    background-position: left 0;
    padding-left: 40px;
    background-size: 25px;
    font-size: 20px;

    color: #023866;

    &_horraire {
      // Prevent parcel from embedding the file
      background-image: url("https://1001audios.fr/images/icone_horraire.svg");
    }
    &_img {
      background-image: url("https://1001audios.fr/images/icone_img.svg");
    }
    &_info {
      background-image: url("https://1001audios.fr/images/icone_info.svg");
    }
    &_localisation {
      background-image: url("https://1001audios.fr/images/icone_localisation.svg");
    }
    &_mail {
      background-image: url("https://1001audios.fr/images/icone_mail.svg");
    }
    &_marque {
      background-image: url("https://1001audios.fr/images/icone_marque.svg");
    }
    &_mut {
      background-image: url("https://1001audios.fr/images/icone_mut.svg");
    }
    &_offre {
      background-image: url("https://1001audios.fr/images/icone_offre.svg");
    }
    &_pay {
      background-image: url("https://1001audios.fr/images/icone_pay.svg");
    }
    &_user {
      background-image: url("https://1001audios.fr/images/icone_user.svg");
    }
    &_web {
      background-image: url("https://1001audios.fr/images/icone_web.svg");
    }
    &_orl {
      background-image: url("https://1001audios.fr/images/icone_orl.png");
    }
  }
  .logo {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    img {
      height: auto;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }
    line-height: 130px;
  }
  .small-table {
    font-size: 14px;
    th {
      border-top: none;
    }
  }
  table.no-first-line {
    tr:first-child td {
      border-top: none;
    }
  }
  .small-logo {
    margin-right: 21px;
    margin-left: 21px;
    max-width: 120px;
    margin-bottom: 20px;
  }
  .payment-item,
  .service-item {
    line-height: 1.714;
    font-size: 14px;
  }

  .payment-title {
    display: inline-block;
    margin-left: 17px;
  }

  table.no-interlines {
    td {
      border-top: none;
    }
  }
  .main-infos {
    p {
      margin: 5px;
    }
  }

  .floating-rdv-button {
    display: block;
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    height: 34px;
    z-index: 500;

    @include media-breakpoint-up(md) {
      a {
        background-color: #001d55 !important;
        border: 1px solid #001d55 !important;
        box-shadow: 0 0 10px 5px #0000002e !important;
      }
    }
  }
  .fiche-buttons-container {
    /*     @include media-breakpoint-down(sm) {
      position: fixed;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      height: 34px;
      z-index: 200;
      line-height: 34px;
      .phone-button {
        border-radius: 20px;
        display: inline-block;
        min-width: 130px;
        &.full {
          display: block;
          width: 220px;
          margin: auto;
        }
        height: 34px;
        margin-top: 0 !important;
      }
      .telephone {
        min-width: 130px;
        &.full {
          display: block;
          width: 220px;
          margin: auto;
        }
        &:not(.full) {
          display: block;
          margin-left: 15px;
          float: left;
        }
        background: white;
        border-radius: 6px;
        border: 1px solid $gray-light;
        height: 34px;
      }
      .rdv-button {
        margin-top: 0 !important;
        display: block;
        margin-left: 20px;
        display: inline-block;
      }
    } */

    /* height: 34px; */
    line-height: 34px;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .phone-button {
      border-radius: 20px;
      display: inline-block;
      min-width: 145px;
      margin-right: 5px;
    }
    .rdv-button {
    }
    .telephone {
      min-width: 170px;
      height: 32px;
      line-height: 30px;
      text-align: left;
      position: relative;
      top: 2px;
      margin-top: 5px;
      margin-bottom: 1px;
      margin-right: 10px;
    }
  }
  .block-payment {
  }
  .block-map {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    /* bottom: 10px; */
    top: 96px;
  }
  .selected-day {
    color: #0c0;
  }
  .personalized-offer {
    margin-top: -6px;
    margin-bottom: 15px;
    display: inline-block;
    color: #ff0030;
    font-size: 15px;
    font-weight: 500;
  }
  .valid-until {
    font-size: 14px;
  }

  .step-container {
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
  }

  .green-check {
    color: white;

    position: absolute;
    width: 28px;
    height: 28px;
    left: -14px;
    top: 0px;
    text-align: center;
    font-weight: 700;
    line-height: 28px;
    border-radius: 50%;
    background-color: #17b355;
    color: #fff;
    transition: background-color 0.3s linear;
  }

  .pricing-text {
    color: #435f71;
    font-size: 16px;
    font-weight: 600;
  }

  .pricing-card {
    border-left: 3px solid #00d9d9;
  }

  .footer-text {
    font-size: 14px;
  }

  .card {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

  .ask-for-rdv {
  }
}

#rdvModal {
  label {
    font-size: 14px;
  }
  .modal-title {
    margin: auto;
  }
  .modal-dialog {
    max-width: 450px;
  }
  .modal-content {
    background-color: #f5faff;
  }
  .modal-body {
    padding-left: 40px;
    padding-right: 40px;
  }
  .modal-footer {
    display: block;
    text-align: center;
  }
}

hr.white {
  border-top: 1px solid white;
}

.list-orls {
  .single-orl {
    .orl-title {
      font-size: 15px;
      color: #328cff;
      margin-bottom: 5px;
    }
    .orl-address,
    .orl-city {
      font-size: 14px;
    }
    .orl-phone {
      font-size: 14px;
    }
  }
}

.rdv-block {
  // border: 1px solid #f2f3f5;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(67, 95, 113, 0.08);
  border-radius: 8px;
  background-color: white;
  .rdv-header {
    background-color: $color-blue-prices;
    color: white;
    padding: 10px;
    font-size: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 4px 6px rgba(67, 95, 113, 0.25);
    margin-bottom: 30px;

    strong {
      font-weight: 500;
      font-size: 18px;
    }
  }
  .rdv-description {
    // padding: 10px;
    background-color: white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    input {
      width: 80%;
      display: block;
      margin: auto;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #ccc;
      padding: 5px;
      border-radius: 0px;
      margin-bottom: 18px;
      &:focus {
        border-bottom: 2px solid $color-blue-prices;
        margin-bottom: 17px;
      }
    }
    .rdv-text {
      font-size: 16px;
      display: block;
      margin-bottom: 20px;
      margin-right: 6px;
      margin-left: 6px;
      font-weight: 600;

      strong {
        font-weight: 500;
      }
    }
    a:not(.data-protection) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    button {
      margin-top: 15px;
      margin-bottom: 21px;
      background-color: $color-blue-prices;
    }
    .alert {
      margin: 15px;
      margin-bottom: 25px;
    }
  }

  .custom-control-description {
    position: relative;
    top: 2px;
  }

  label.custom-control {
    margin-bottom: 4px;
  }
}

.phoneNumberModal {
  .modal-content {
    margin-top: 118px;
    border: 1px solid rgb(255, 255, 255);
  }
  .addresse {
    font-size: 14px;
  }
  .p-absolute {
    position: absolute;
  }
  .modal-free-call {
    color: rgb(119, 119, 119);
  }
}

.center-topbar {
  background: white;
  padding: 12px 0;
  position: fixed;
  z-index: 110;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s;
  transition: transform 0.2s ease-in-out, opacity 0.2s,
    -webkit-transform 0.2s ease-in-out;
  box-shadow: 0 1px 2px #ccc;

  &.visible {
    @include media-breakpoint-up(lg) {
      opacity: 1;
    }
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    box-shadow: 0 2px 10px 0 rgba(67, 95, 113, 0.2);
  }
  .image-container {
    width: 100%;
    position: relative;
    height: 100%;
  }
  .topbar-image {
    max-width: 100%;
    max-height: 55px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .center-address-header {
    font-size: 15px;
    line-height: 18px;
  }
  .center-address-content {
    font-size: 14px;
    line-height: 18px;
  }
}

.simple-rounded-search {
  width: 80%;
  min-width: 300px;
  position: relative;
  margin: auto;
  height: 40px;
  input {
    width: 100% !important;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 25px;
    height: 40px;
    padding-left: 24px;
    &:focus {
      border: none;
      -webkit-box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
      -moz-box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
      box-shadow: 0px 0px 17px 0px rgba(#000, 0.3);
    }
  }

  button {
    background: transparent;
    border: none;
    color: #328cff;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 11px;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border: none;
      color: #328cff;
    }
  }
}

.non-premium-ad {
  position: sticky;
  top: 102px;

  &.positioned-top {
    top: 25px;
  }
}

.news-title {
  font-size: 16px;
  color: #435f71;
}

.news-text {
  font-size: 14px;
}

.gender-selector {
  width: 80%;
  margin: auto;
  padding-left: 5px;
  color: #999999;
  font-size: 1rem;
  text-align: left;
}

.modal-rdv-form {
  max-width: 330px;
  margin: auto;
  margin-top: 20px;

  input {
    width: 80%;
    display: block;
    margin: auto;
    margin-top: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    border-radius: 0px;
    margin-bottom: 18px;
  }
}

.prevent-p-margin {
  p {
    margin-top: 0;
  }
}

.fiche-opening,
.desktop-fiche-opening,
.mobile-fiche-opening {
  .currently-open {
    font-size: 14px;
    color: #0c0;
    font-weight: 500;
    line-height: 16px;
  }

  .currently-closed {
    font-weight: 500;
    font-size: 14px;
    color: #f22111;
    line-height: 16px;
  }
}

.desktop-fiche-opening {
  text-align: right;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.mobile-fiche-opening {
  margin-top: 20px;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.single-review {
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;

  .review-title {
    font-weight: 600;
    font-size: 13px;
  }

  .review-stars {
    margin-top: 0px;
    margin-bottom: 8px;
  }

  .review-content,
  .review-content-full {
    font-size: 14px;
    /* max-height: 200px;
    overflow: hidden; */
  }

  .review-content-full {
    display: none;
  }

  .expander {
    font-size: 10px;
  }
}

.review-card {
  /* height: 350px; */
}

.review-carousel {
  min-height: 142px;
}

.reviews-desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.reviews-mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.blue-card {
  background: #e0f1fd;
  padding: 10px;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 10px;
  border-radius: 8px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;

  & > p {
    line-height: 30px;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
}

.blue-card-appendix {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 5px;
  line-height: 13px;

  & > p {
    line-height: 13px;
    font-size: 80%;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
}

.link-to-website {
  display: inline-block;
  min-width: 170px;
  border-radius: 20px;
  border-width: 2px;
}
