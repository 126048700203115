.chu-panel {
  &.has-call-image {
    background-image: url("/wwwroot/assets/images/photo_centre_appel.jpg");
    background-repeat: no-repeat;
    background-size: 106px 110px;
    background-position-x: right;
    background-position-y: bottom;
  }

  .description {
    strong {
      font-weight: 600;
    }
  }
}

.centre-secondary-photo-container{

    text-align: center;
    .centre-secondary-photo{
        max-width: 100%;
        border-radius: 10px;
        margin-bottom: 20px;
    }
}
